import React from "react";
import Layout from "../components/Layout";
import * as styles from "../styles/news-details.module.css";
import ContactUs from "../components/ContactUs";

import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import GoHome from "../components/GoHome";

const NewsDetails = ({ data }) => {
  const { excerpt, title, backgroundImage, updatedAt } = data.contentfulNews;

  const news = data.contentfulNews.content;

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return <img className={styles.richImg} src={node.data.target.fixed.src} alt="" />;
      },
    },
  };

  const output = renderRichText(news, options);

  return (
    <Layout>
      <section>
        <div className={styles.imgContainer}>
          <Img fluid={backgroundImage.fluid} />
          <div className={styles.titleBox}>
            <h2>{title}</h2>
            <p className={styles.time}> data: {updatedAt}</p>
            <p>{excerpt}</p>
          </div>
        </div>
        <div className={styles.richContainer}>{output}</div>
        <GoHome />
        <ContactUs />
      </section>
    </Layout>
  );
};

export const query = graphql`
  query MyQuery($slug: String!) {
    contentfulNews(url: { eq: $slug }) {
      id
      url
      title
      updatedAt(formatString: "DD.MM.yy")
      excerpt
      content {
        __typename
        raw
        references {
          __typename
          ... on ContentfulAsset {
            contentful_id
            fixed(width: 1600) {
              __typename
              width
              height
              src
              srcSet
            }
          }
        }
      }
      backgroundImage {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;

export default NewsDetails;
